import { SelectInput, useGetList, useTranslate } from 'react-admin';
import type { Validator } from 'react-admin';

interface SelectTemplatesInterface {
  source: string,
  validate?: Validator | Validator[],
  alwaysOn?: boolean,
}

const SelectCredentialTypeCreation = ({source, validate, alwaysOn}: SelectTemplatesInterface) => {
  const loadingValue = [{id: false, name: "loading"}];
  const { data: choices, isLoading } = useGetList(
    "CredentialTypeCreation",
    {
      pagination: { page: 1, perPage: 100 },
      sort: {field: 'id', order: 'ASC'},
      filter: { archivedAtIsSet: false }
    },
  );

  const optionRenderer = choice => `${choice.id} - ${choice.credentialName}`;

  return(
    <SelectInput
      source={source}
      validate={validate}
      choices={ isLoading ? loadingValue : choices }
      optionText={optionRenderer}
      translateChoice={false}
      alwaysOn={alwaysOn}
      sx={{minWidth: "20em", maxWidth: "25em"}}
    />
)};

export default SelectCredentialTypeCreation;